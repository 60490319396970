<template>
  <div class="user-content">
    <div class="user-info-list">

      <el-table :data="data.tableData" style="width: 100%" :max-height="maxHeight">

        <el-table-column prop="landInfo.title" label="土地名称" show-overflow-tooltip>
          <template #default="scope">
            <span style="cursor: pointer;" @click="toDetail(scope.row.landInfo)" >{{scope.row.landInfo.title}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="auctionInfo.resourceNo" label="资源编号" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="auctionInfo.startPrice" label="起始价" width="100">
          <template #default="scope"> {{ scope.row.auctionInfo.startPrice || 0 }} 万元 </template>
        </el-table-column>
        <el-table-column prop="auctionInfo.currentPrice" label="当前价" width="100">
          <template #default="scope">
            {{ scope.row.auctionInfo.currentPrice || 0 }} 万元
            <!-- <el-link type="primary">（查看）</el-link> -->
          </template>
        </el-table-column>
        <el-table-column prop="auctionInfo.buyerCount" label="竞价人数" width="100">
        </el-table-column>
        <el-table-column prop="auctionInfo.buyerCount" label="竞价人数" width="100">
        </el-table-column>

        <el-table-column prop="auditStatus" label="我的竞价资格" width="130">
          <template #default="scope">
            {{ scope.row.auditStatus == 2 ? '审核通过' : scope.row.auditStatus == 3 ? '审核不通过' : '待审核' }}
          </template>
        </el-table-column>

        <el-table-column prop="action" label="操作" :width="width">
          <template #default="scope">
            <el-button  text type="primary" small  @click="onSnedApply(scope.row)" v-if="!isEnd(scope.row)"> 出价 </el-button>
            <el-button text type="primary" small v-if="showComplementary(scope.row)" @click="onSendApply(scope.row)"> 补充资料 </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="user-info-list-page">
        <el-pagination background layout="prev, pager, next" :page-count="data.totalPage" :current-page="data.currentPage" @current-change="pageChange" >
        </el-pagination>
      </div>
      <auction-trade-complementary-files-dialog ref="AuctionTradeComplementaryFilesDialogRef" @refresh="pageChange" />

    </div>
  </div>
</template>
<script setup>
import { reactive,onMounted,ref,defineAsyncComponent  } from 'vue'
import CommonService, {fetchListingBuyerList} from '@/service/CommonService';
import { useDict, findLabel } from "@/hooks/dict";
import {Message,MessageBox} from "element-ui";
import {useRouter} from "vue-router/composables";

const router = useRouter();
const { land_purpose } = useDict('land_purpose')
const AuctionTradeComplementaryFilesDialog = defineAsyncComponent(() => import('@/components/AuctionTradeComplementaryFiles.vue'));
const width = ref({value:80});
const data = reactive({
  pageSize:10,
  currentPage:1,
  totalPage:1,
  maxHeight:250,
  tableData: [

  ]
});
const AuctionTradeComplementaryFilesDialogRef = ref();
let maxHeight = '10%';
const pageChange = (page) =>{
  loadLand(page);

}

const isEnd = (row) =>{
  let date = new Date()
  return row.auctionInfo.auctionEndTime < date.getTime();
}

const showComplementary = (row) => {
  let time = new Date().getTime();

  let result = time > row.auctionInfo.auctionEndTime
      && row.auctionInfo.transactionStatus === "processing"
      && row.auctionInfo.maxPriceUserId === localStorage.getItem('user_id');
  if (result) {
    width.value = 230;
  }
//  return result;
  return false
} ;

const loadLand = (page) =>{
  CommonService.fetchListingBuyerList({
    size:data.pageSize,
    current:page
  }).then((res)=>{
    if(res.code === 0){
      console.log(res.data.records)

        for (let i=0;i<res.data.records.length;i++)
        {
          if(res.data.records[i].landInfo!=undefined){
          console.log( res.data.records[i].landInfo.title)
          res.data.records[i].landInfo.title= res.data.records[i].landInfo.title.replace("出租出租",'出租')
          res.data.records[i].landInfo.title= res.data.records[i].landInfo.title.replace("转让转让",'转让')
        }
      }


      data.tableData = res.data.records;
      data.totalPage = res.data.pages;
      data.currentPage = res.data.current;
    }
  })
}

onMounted(() => {
  maxHeight = window.screen.height <= 900 ? 250 : '';
  loadLand(1);
})

const onSnedApply =  (row) => {
  if (row.auditStatus !== 2) {
    Message.error('未通过审核！暂不能出价');
    return false;
  }
  try {
    let minPrice = parseInt((row.auctionInfo.currentPrice?? row.auctionInfo.startPrice)) + parseInt(row.auctionInfo.rise)
    MessageBox.prompt('请输入竞拍价格且需不小于 ' + minPrice + ' (单位:万元)', '', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      inputPattern: /^[0-9.]*$/,
      inputErrorMessage: '请输入正确的价格'
    }).then(({ value }) => {
      if (value < minPrice) {
        Message.error("请输入不小于 " + minPrice + ' (万元)的竞拍价');
        return;
      }
      let tmp = (value - parseInt(row.auctionInfo.startPrice)) / row.auctionInfo.rise;
      if (!Number.isInteger(tmp)) {
        Message.error('出价需为加价幅度整数倍');
        return;
      }
      row.currentPrice = Number(value);
      let params = {
        biddingPrice:value,
        auctionId:row.auctionInfo.id,
        buyerId:localStorage.getItem('user_id')
      }
      try {
        console.log(22);
        CommonService.addAuctionPrice(params).then((res) => {
          if (res.code === 0) {
            Message.success('报价成功');
          }else {
            Message.error(res.msg);
          }
        })
      } catch(err) {
        console.log(err)
        Message.error('报价失败');
        return;
      }
    }).catch(() => {
      Message.info({
        type: 'info',
        message: '取消输入'
      });
    });
  } catch {
    return;
  }
};
const onSendApply = async (params) => {

  AuctionTradeComplementaryFilesDialogRef.value.openDialog(params.auctionInfo, 'listing');
};

const   toDetail=(landInfo)=>{
  if(landInfo.auctionInfo &&  landInfo.transactionWay === "listing"){
    router.push("/trade/gp/"+this.landInfo.auctionInfo.id);
  }
  else if( landInfo.transactionWay === "auction" && landInfo.auctionInfo){
    router.push("/land/auction/"+landInfo.id);
  }
  else{
    router.push("/land/detail/"+landInfo.id);
  }
}


</script>
